<template>
  <div />
</template>

<script>
/* eslint-disable no-extra-semi */
import { mapGetters } from 'vuex'

function runEmbedWidget(srcUrl, dialogId, displayName, companyName) {
  const supportChatIframe = document.getElementById('paConnectDialogIFrame')
  if (supportChatIframe) {
    localStorage.removeItem('pa-visitorId')
    sessionStorage.removeItem('pa-button-prompted')
    sessionStorage.removeItem('pa-json')
    supportChatIframe.remove()
  }
  const scriptElements = document.querySelectorAll('script[data-owner="letsconnect"]')
  scriptElements.forEach(function (scriptElement) {
    scriptElement.parentNode.removeChild(scriptElement)
  })
  ;(function (d, s) {
    const l = d.createElement(s),
      e = d.getElementsByTagName(s)[0]
    l.async = true
    l.type = 'text/javascript'
    l.src = srcUrl
    l.id = dialogId
    l.dataset.owner = 'letsconnect'
    l.dataset.visitorDisplayName = displayName ? displayName.replace(/\s/g, '%20') : ''
    l.dataset.visitorCompanyName = companyName ? companyName.replace(/\s/g, '%20') : ''
    e.parentNode.insertBefore(l, e)
  })(document, 'script')
}

export default {
  name: 'EmbedWidget',

  data() {
    return {
      isSetupCompleted: false
    }
  },

  props: {
    isAuth: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company'
    })
  },

  created() {
    this.setupEmbedWidget()
  },

  watch: {
    company() {
      if (this.company && this.company.name && !this.isSetupCompleted) {
        this.isSetupCompleted = true
        this.setupEmbedWidget()
      }
    }
  },

  methods: {
    async setupEmbedWidget() {
      if (this.isAuth) {
        if (!this.activeUserInfo || !this.activeUserInfo.company || !this.company || !this.company.name) {
          return
        }
      }

      const settingsDoc = await this.$db.collection('settings').doc('support-chat').get()

      if (!settingsDoc.exists) {
        return
      }

      const settingsData = settingsDoc.data()

      let dialogId = this.isAuth ? settingsData.SUPPORT_DIALOG_ID_DE : settingsData.WEBSITE_DIALOG_ID_DE

      if (this.$i18n.locale !== 'de') {
        dialogId = this.isAuth ? settingsData.SUPPORT_DIALOG_ID_EN : settingsData.WEBSITE_DIALOG_ID_EN
      }

      const hostUrl = `${this.$config.hostUrl}/embed.js`

      if (this.isAuth) {
        runEmbedWidget(hostUrl, dialogId, this.activeUserInfo.displayName, this.company.name)
      } else {
        runEmbedWidget(hostUrl, dialogId)
      }

      this.isSetupCompleted = true
    }
  }
}
</script>

<style lang="scss">
#paConnectDialogIFrame.__pathadvice-dialog-minimized__ {
  z-index: 9999 !important;
}
</style>
